import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import jwt_decode from 'jwt-decode'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import dashBoard from './dashboard'
import kurum from './kurum'
import finans from './finans'
import kurumInfo from './kurumInfo'
import rapor from './rapor'
import rezervasyon from './rezervasyon'
import services from './services'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    POST_URL: process.env.NODE_ENV === 'production' ? 'https://finans.isletmem.com.tr/api/webservis.php' : 'https://finans.isletmem.com.tr/api/webservis.php',
    userToken: '',
    isAuth: null,
    level: 0,
    userData: [],
    tarife: [],
    firmaPreview: false,
  },
  modules: {
    services,
    rezervasyon,
    rapor,
    kurumInfo,
    finans,
    kurum,
    dashBoard,
    auth,
    app,
    appConfig,
    verticalMenu,
  },
  actions: {
    tarife({
      commit,
      state,
    }) {
      let token = localStorage.getItem('raporUserToken')
      commit('setWorkDay', localStorage.getItem('raporCurrentDate'))
      const data = {
        crm_token: token,
        serviceName: 'firma',
        methodName: 'tarifeListesi',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      if (token) {
        $.ajax({
          type: 'POST',
          url: state.POST_URL,
          data,
          success(res) {
            state.isAuth = true
            commit('setTarife', res)
          },
          dataType: 'json',
        })
      } else {
        router.push('/login')
      }
    },
    async authControl({
      commit,
      state,
    }) {
      const today = new Date()
      const dd = String(today.getDate())
        .padStart(2, '0')
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0')
      const yyyy = today.getFullYear()
      let todayy
      todayy = yyyy + '-' + mm + '-' + dd
      localStorage.setItem('raporCurrentDate', todayy)
      let token = localStorage.getItem('raporUserToken')
      const data = {
        crm_token: token,
        serviceName: 'dashBoard',
      }
      if (token) {
        $.ajax({
          type: 'POST',
          url: state.POST_URL,
          data,
          success(res) {
            state.isAuth = true
            commit('kurumListAction', res.kurumListesi)
            // eslint-disable-next-line no-underscore-dangle
            router.push({ path: router.history._startLocation })
            commit('setToken', res.userToken)
            commit('setUserData', jwt_decode(res.userToken))
          },
          error(err) {
            state.isAuth = false
            if (err.responseJSON.tokenStatus !== 503) {
              commit('removeToken')
              router.push('/login')
            } else {
              router.push('/expariedLogin')
            }
          },
          dataType: 'json',
        })
      } else {
        router.push('/login')
      }
    },
  },
  mutations: {
    setTarife(state, item) {
      state.tarife = item.tarifeListesi
    },
    removeUserData(state) {
      state.level = 0
      state.userData = []
    },
    setUserData(state, jwt) {
      state.level = jwt.level
      state.userData = jwt
      localStorage.setItem('raporFirmaKodu', jwt.firmaKodu)
      localStorage.setItem('raporFullName', jwt.FullName)
      localStorage.setItem('raporUserName', jwt.userName)
    },
    setToken(state, token) {
      state.userToken = token
      localStorage.setItem('raporUserToken', token)
    },
    removeToken(state) {
      state.userToken = ''
      localStorage.removeItem('userToken')
    },
  },
  getters: {
    tarife(state) {
      return state.tarife
    },
    getFrimaPreview(state) {
      return state.firmaPreview
    },
    getRole(state) {
      return state.level
    },
    isAuthenticated(state) {
      return state.isAuth
    },
    notificationSettings: () => (val) => {
      if (val.result) {
        const cevap = JSON.stringify(val.result.errMsg)
        const reply = cevap.slice(1, -1)
        return reply
      }
    },
  },
  strict: process.env.DEV,
})
